<template>
  <v-card>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit, invalid }">
      <form
        autocomplete="off"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="reset"
      >
        <v-toolbar v-if="title" flat dense color="bg_section" class="">
          <v-toolbar-title class="subtitle-2">
            <v-icon v-if="title_icon" left>{{ title_icon }}</v-icon>
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>

        <v-divider class="mb-3" />
        <v-card-text>
          <slot />

          <v-alert v-if="invalid" type="info">
            Per procedere all'invio, assicurati di aver riempito tutti i campi
            obbligatori.
          </v-alert>
        </v-card-text>

        <v-divider class="mb-3" />

        <v-card-actions>
          <v-btn
            type="submit"
            :color="invalid ? 'red' : 'green'"
            class="mr-3"
            dark
          >
            <v-icon left>mdi-content-save-edit</v-icon>
            Invia
          </v-btn>

          <v-btn depressed @click="clear">
            Resetta
          </v-btn>
        </v-card-actions>
      </form>
    </ValidationObserver>
  </v-card>
</template>

<script>
export default {
  name: 'FormCard',
  props: {
    title: {
      type: String,
      default: null,
    },
    title_icon: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit')
    },
    clear() {
      this.$refs.observer.reset()
      this.$emit('reset')
    },
  },
}
</script>

<style scoped></style>
