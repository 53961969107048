<template>
  <v-container fluid>
    <FormCard
      title="Modifica password"
      title_icon="mdi-account"
      @submit="onSubmit"
      @reset="onReset"
    >
      <h4 v-if="currentUser.is_temp_password"
        >Cambia la password temporanea con una personale</h4
      >
      <v-row>
        <FormItem
          v-model="password_to_send"
          label="Password"
          rules="required|pwd_len|pwd_schars|pwd_chars|pwd_num|confirm:@password_confirm"
          name="password"
          type="password"
          :cols="12"
          :sm="12"
          :md="6"
          :lg="6"
          :xl="6"
        />
        <FormItem
          v-model="password_confirm"
          vprovname="confirmation"
          rules="required|pwd_len|pwd_schars|pwd_chars|pwd_num"
          name="Conferma password"
          type="password"
          :cols="12"
          :sm="12"
          :md="6"
          :lg="6"
          :xl="6"
        />
      </v-row>
    </FormCard>
  </v-container>
</template>

<script>
import FormItem from '@components/forms/FormItem.vue'
import FormCard from '@components/forms/FormCard.vue'
import { mapActions, mapState } from 'vuex'

export default {
  page: {
    title: 'Aggiorna password',
  },
  components: {
    FormItem,
    FormCard,
  },
  data: () => ({
    password_confirm: null,
    password_to_send: null,
  }),
  computed: {
    ...mapState('auth', {
      currentUser: (state) => state.current.user,
    }),
  },
  methods: {
    ...mapActions('users', ['updatePassword']),
    async onSubmit() {
      await this.updatePassword({
        id: this.currentUser.id,
        password_to_send: this.password_to_send,
      })

      await this.$router.push(
        this.$route.query.redirectFrom || { name: 'home' }
      )
    },
    onReset() {
      this.password_confirm = null
      this.password_to_send = null
    },
  },
}
</script>
